@media (min-width: 900px) {
    html{
        height: 100%;
        overflow: hidden;
        font-family: "Consolas", serif;
    }

    body{
        background-color: #e8ab34;
        width: 100%;
        height: 87%;
        margin: 0;
    }

    #root{
        width: 98vmax;
        height: 100%;
    }

    .App{
        width: 100%;
        height: 100%;
        padding: 1%;
        flex-direction: column;
    }

    .Content{
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .navbar{
        display: flex;
        flex-direction: row;
    }

    .material-symbols-outlined{
        font-size: 4vh;
        margin: 2.25vh 1vw;
        user-select: none;
    }

    .material-symbols-outlined:hover{
        font-size: 4vh;
        margin: 2.25vh 1vw;
        user-select: none;
        transition-duration: 0.4s;
        border-radius: 10px;
        box-shadow: 5px 10px #d18e24;
        background-color: #ffdd9d;
    }

    .sidebar{
        flex: 2;
        display: flex;
        flex-direction: column;
    }

    .Left{
        background-color: #ffdd9d;
        flex: 2;
        display: flex;
        flex-direction: column;
        box-shadow: inset 5px 10px #d18e24;
        margin-right: 1vw;
        padding: 2vh 1vw;
    }

    .Rooms{
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .Active{
        background-color: #003b49;
        color: #ffc845;
        box-shadow: 5px 10px #d18e24;
        border-radius: 20px;
    }

    button{
        font-size: 3vh;
        height: 10vh;
        background-color: #ffc845;
        border-radius: 20px;
        color: #003b49;
        box-shadow: 5px 10px #d18e24;
        margin-bottom: 1vh;
    }

    button:hover{
        background-color: #003b49;
        color: #ffc845;
        box-shadow: 5px 10px #d18e24;
        transition-duration: 0.4s;
        border-radius: 20px;
    }

    .Right{
        background-color: #ffdd9d;
        box-shadow: inset 5px 10px #d18e24;
        flex: 8;
        display: flex;
        flex-direction: column;
        padding: 2vh 1vw;
    }

    .MessageList{
        margin-left: 1vw;
        margin-right: 1vw;
        padding: 2vh 1vw;
        flex: 8;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        background-color: #ffe8bc;
        box-shadow: inset 5px 10px #d18e24;
    }

    .SendMessageForm{
        margin-left: 1vw;
        margin-right: 1vw;
        flex: 2;
        background-color: #ffe8bc;
        box-shadow: inset 5px 10px #d18e24;
        display: flex;
        padding-left: 5px;
    }

    .TypeArea{
        flex:8;
        font-size: 3vh;
        overflow-x: hidden;
        resize: none;
        border-bottom: none;
        font-family: "Consolas", serif;
    }

    .TypeArea::placeholder {
        font-family: "Consolas", serif;;
    }

    .Submit{
        flex: 2;
        font-size: 3vh;
        background-color: #ffc845;
        color: #003b49;
        border-bottom: none;
        border-right: none;
        border-width: 1px;
    }

    .Submit:hover{
        background-color: #003b49;
        color: #ffc845;
        transition-duration: 0.4s;
    }

    .Bot{
        display: flex;
        flex-direction: column;
        padding: 1vh 0.5vw;
        margin-bottom: 1vh;
    }

    div#Bot{
        background-color: #ffc845;
        color: #003b49;
    }

    .Tester{
        display: flex;
        flex-direction: column;
        padding: 1vh 0.5vw;
        margin-bottom: 1vh;
    }

    div#Tester{
        background-color: #ffc845;
        color: #003b49;
    }

    .User{
        display: flex;
        flex-direction: column;
        text-align: right;
        padding: 1vh 0.5vw;
        margin-bottom: 1vh;
    }

    div#User{
        background-color: #003b49;
        color: #ffc845;
        margin-left: 16%;
    }

    .Bubble{
        padding: 2%;
        border-radius: 20px;
        max-width: 80%;
        box-shadow: 5px 10px #d18e24;
    }

    .Text{
        font-size: 3vh;
    }

    .Username{
        font-size: 2vh;
        padding-top: 1%;
        font-weight: bolder;
    }
}

@media (max-width: 900px) {
    html{
        height: 100%;
        overflow: hidden;
        font-family: "Consolas", serif;
    }

    body{
        background-color: #e8ab34;
        width: 100%;
        height: 87%;
        margin: 0;
    }

    #root{
        width: auto;
        height: 100%;
    }

    .App{
        width: 95%;
        height: 95%;
        padding: 1%;
        flex-direction: column;
    }

    .Content{
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .navbar{
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .icon{
        flex: 1
    }

    .material-symbols-outlined{
        font-size: 4vh;
        margin: 2.25vh 1vw;
        user-select: none;
    }

    h1{
        font-size: 2.25vh;
        flex: 9;
    }

    h2{
        font-size: 2vh;
    }

    .sidebar{
        flex: 2;
        display: flex;
        flex-direction: column;
    }

    .Left{
        background-color: #ffdd9d;
        flex: 2;
        display: flex;
        flex-direction: column;
        box-shadow: inset 5px 10px #d18e24;
        margin-left: 3%;
        padding: 3% 5%;
    }

    .Rooms{
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .Active{
        background-color: #003b49;
        color: #ffc845;
        box-shadow: 5px 10px #d18e24;
        border-radius: 20px;
    }

    button{
        font-size: 1.75vh;
        height: 10vh;
        background-color: #ffc845;
        border-radius: 20px;
        color: #003b49;
        box-shadow: 5px 10px #d18e24;
        margin-bottom: 1vh;
    }

    button:hover{
        background-color: #003b49;
        color: #ffc845;
        box-shadow: 5px 10px #d18e24;
        transition-duration: 0.4s;
        border-radius: 20px;
    }

    .Right{
        background-color: #ffdd9d;
        box-shadow: inset 5px 10px #d18e24;
        flex: 8;
        display: flex;
        flex-direction: column;
        margin-left: 3%;
        padding: 3% 5%;
    }

    .MessageList{
        padding: 3% 5%;
        flex: 8;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        background-color: #ffe8bc;
        box-shadow: inset 5px 10px #d18e24;
    }

    .SendMessageForm{
        flex: 2;
        background-color: #ffe8bc;
        box-shadow: inset 5px 10px #d18e24;
        display: flex;
        padding-left: 5px;
    }

    .TypeArea{
        flex:8;
        font-size: 1.75vh;
        overflow-x: hidden;
        resize: none;
        border-bottom: none;
        font-family: "Consolas", serif;
    }

    .TypeArea::placeholder {
        font-family: "Consolas", serif;;
    }

    .Submit{
        flex: 2;
        font-size: 1.75vh;
        background-color: #ffc845;
        color: #003b49;
        border-bottom: none;
        border-right: none;
        border-width: 1px;
    }

    .Submit:hover{
        background-color: #003b49;
        color: #ffc845;
        transition-duration: 0.4s;
    }

    .Bot{
        display: flex;
        flex-direction: column;
        padding: 1vh 0.5vw;
        margin-bottom: 1vh;
    }

    div#Bot{
        background-color: #ffc845;
        color: #003b49;
    }

    .Tester{
        display: flex;
        flex-direction: column;
        padding: 1vh 0.5vw;
        margin-bottom: 1vh;
    }

    div#Tester{
        background-color: #ffc845;
        color: #003b49;
    }

    .User{
        display: flex;
        flex-direction: column;
        text-align: right;
        padding: 1vh 0.5vw;
        margin-bottom: 1vh;
    }

    div#User{
        background-color: #003b49;
        color: #ffc845;
        margin-left: 16%;
    }

    .Bubble{
        padding: 2%;
        border-radius: 10px;
        max-width: 80%;
        box-shadow: 5px 10px #d18e24;
    }

    .Text{
        font-size: 1.75vh;
    }

    .Username{
        font-size: 1.5vh;
        padding-top: 1%;
        font-weight: bolder;
    }
}
